import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  public token: string = '';
  private expiresAt: number = 0;

  constructor(private authService: AuthService) {
    var currentToken = localStorage.getItem('access_token');
    var tokenExpiration = localStorage.getItem('access_token_expiration_time');

    if (currentToken !== null && tokenExpiration !== null) {
      this.token = currentToken;
      this.expiresAt = Number.parseInt(tokenExpiration);
    }
  }

  setToken = (newToken: string, expiresIn: number) => {
    this.token = 'Bearer ' + newToken;
    this.expiresAt = Date.now() + expiresIn * 1000;

    localStorage.setItem('access_token', this.token);
    localStorage.setItem(
      'access_token_expiration_time',
      this.expiresAt.toString()
    );
  };

  public getToken = async () => {
    let now = Date.now();
    if ((this.expiresAt ?? 0) < now && this.token !== '') {
      try {
        var newToken = await this.authService.refreshToken();
        if (newToken) {
          this.setToken(newToken.id_token, newToken.expires_in);
        } else {
          this.deleteToken();
        }
      } catch (error) {
        this.deleteToken();
      }
    }

    return this.token;
  };

  public deleteToken = () => {
    this.token = '';
    this.expiresAt = 0;
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expiration_time');
    this.authService.logout();
  };
}
