import {
  Injectable,
  InjectionToken,
  ModuleWithProviders,
  Provider,
  signal,
} from '@angular/core';
import { User } from '../../models/general/user';
import {
  Observable,
  catchError,
  flatMap,
  map,
  mergeMap,
  throwError,
} from 'rxjs';
import { TokenService } from '../auth/token.service';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../settings.service';
import { SupplierAccount } from '../../models/general/supplier-account';
import { error } from 'console';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  getUser = (): User => {
    const user: User = JSON.parse(localStorage.getItem('current_user'));
    return user;
  };

  public userSignal = signal<User | null>(null);
  public isAuthenticated = signal(false);
  public isLoading = signal(false);
  constructor(private tokenService: TokenService, private http: HttpClient) {}

  isLoggedIn = this.userSignal() !== null;
  logout = () => {
    this.tokenService.deleteToken();
    this.userSignal.set(null);
    this.isAuthenticated.set(false);
    localStorage.removeItem('selected-service-category');
  };

  load = (): Observable<any> => {
    this.isLoading.set(true);

    let chain: Observable<any> = this.http.get<User>(
      SettingsService.settings.api.tenantServiceUri + '/users/me'
    );

    return chain.pipe(
      catchError((err) => {
        this.isLoading.set(false);
        return throwError(() => err);
      }),
      mergeMap((u) => {
        return this.http
          .get<Array<SupplierAccount>>(
            `${SettingsService.settings.api.fleetSupplierServiceUri}/supplier-account/by-user-id/${u.id}`
          )
          .pipe(
            map((sa) => {
              if (sa && sa.length > 0) {
                u.supplierAccounts = sa;
                this.setSupplierAccount(sa[0], u);
              }
              this.isLoading.set(false);
              this.userSignal.set(u);
              this.isAuthenticated.set(true);
              return u;
            })
          );
      })
    );
  };

  getIdPSourceForUser(partnerName: string, userId: string): Observable<any> {
    const url = SettingsService.settings.api.tenantServiceUri + '/federatedAuthenticationProvider/user?partnerName=' + partnerName + '&userId=' + userId;
    return this.http.get<any>(url);
}

  selectSupplierAccount(supplierAccountId: string){
    var user = this.userSignal();
    var selectedSupplier = user.supplierAccounts.find(sa => sa.id == supplierAccountId);
    if(selectedSupplier != null){
      this.setSupplierAccount(selectedSupplier, user);
      this.userSignal.set(user);
    }
  }

  setSupplierAccount(supplierAccount: SupplierAccount, user: User){
    user.supplierAccount = Object.assign(new SupplierAccount(), supplierAccount);
    user.supplierAccountId = supplierAccount.id;
    user.supplierAccount.serviceCategories = supplierAccount.serviceCategories; //['vehicleFinancing', 'vehicleConversion', 'tireManagement']

    var selectedServiceCategory = localStorage.getItem(
      'selected-service-category'
    );
    if (
      selectedServiceCategory &&
      user.supplierAccount.serviceCategories.some(
        (s) => s == selectedServiceCategory
      )
    ) {
      user.supplierAccount.serviceCategory = selectedServiceCategory;
    } else {
      if (user.supplierAccount.serviceCategories.length == 1) {
        user.supplierAccount.serviceCategory = user.supplierAccount.serviceCategories[0];
        localStorage.setItem(
          'selected-service-category',
          user.supplierAccount.serviceCategory
        );
      }
    }
  }
}
