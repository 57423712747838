import { SupplierAccount } from "./supplier-account";

export class UserDto {
    id: string;
    userId: string;
    isActive: boolean;
    identityProviderUserId: string;
    email: string;
    phoneNumber: string;
    firstName: string;
    lastName: string;
    fullName: string;
    accessToken: string;
    idToken: string;
    tokenExpiration: Date; 
    supplierAccount: SupplierAccount;
    supplierAccountId: string;
    supplierAccountUserId: string;
    supplierAccounts: Array<SupplierAccount>;
}