import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    getClientMessage(error: Error): string {
        if (!navigator.onLine) {
            return 'No Internet Connection';
        }

        var clientTxt = "CLIENT: ";
        var errorMessage = error.message ? error.message : error.toString();
        return clientTxt + " " + errorMessage;
    }

    getClientStack(error: Error): string {
        return error.stack;
    }

    getServerMessage(error: HttpErrorResponse): string {
        if (error.error)
            return error.error.Message;

        return "SERVER: " + JSON.stringify(error);
    }

    getServerStack(error: HttpErrorResponse): string {
        // handle stack trace
        if (error.error)
            return error.error.StackTraceString;

        return JSON.stringify(error);
    }
}