import { Routes } from '@angular/router';

export const ROUTES: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadComponent: () =>
          import('./views/home/home.component').then(
            (mod) => mod.HomeComponent
          ),
      },
      {
        path: 'leasing',
        loadChildren: () =>
          import('./views/leasing/leasing.routes').then(
            (l) => l.LEASING_ROUTES
          ),
      },
      {
        path: 'vehicle-converter',
        loadChildren: () =>
          import('./views/vehicle-converter/vehicle-conversion.routes').then(
            (v) => v.VEHICLE_CONVERSION_ROUTES
          ),
      },
      {
        path: 'vehicle-recond',
        loadChildren: () =>
          import('./views/vehicle-recond/vehicle-recond.routes').then(
            (v) => v.VEHICLE_RECOND_ROUTES
          ),
      },
      {
        path: 'tire-management',
        loadChildren: () =>
          import('./views/tire-management/tire-management.routes').then(
            (v) => v.TIRE_MANAGEMENT_ROUTES
          ),
      },
      {
        path: 'customers',
        loadChildren: () =>
          import('./views/customers/customers.routes').then(
            (c) => c.CUSTOMER_ROUTES
          ),
      },
      {
        path: 'portify-connect',
        loadChildren: () =>
          import('./views/portify-connect/portify-connect.routes').then(
            (i) => i.PORTIFY_CONNECT_ROUTES
          ),
      },
      {
        path: 'integrations',
        loadChildren: () =>
          import('./views/integrations/integrations.routes').then(
            (i) => i.INTEGRATIONS_ROUTES
          ),
      },
      {
        path: 'vehicle-sales-assignments',
        loadChildren: () =>
          import('./views/vehicle-sales-assignment/vehicle-sales-assignment.routes').then(
            (i) => i.VEHICLE_SALES_ASSIGNMENT_ROUTES
          ),
      },
    ],
  },
  {
    path: 'home',
    loadComponent: () =>
      import('./views/home/home.component').then((mod) => mod.HomeComponent),
  },
  {
    path: 'not-authorized',
    loadComponent: () =>
      import('./views/misc/not-authorized').then(
        (mod) => mod.NotAuthorizedComponent
      ),
  },
  {
    path: 'about',
    loadComponent: () =>
      import('./views/about/about.component').then((mod) => mod.AboutComponent),
  },
  {
    path: 'callback',
    loadComponent: () =>
      import('./views/callback/callback.component').then(
        (mod) => mod.CallbackComponent
      ),
  },
];
