import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';
import { ExceptionTypeEnum, HandledException, createWarning } from './handled-exception';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
  })

  
export class NotificationService {

    private handledExceptions = new Array<HandledException>();

  constructor(private translateService: TranslateService) {
    this.handledExceptions = [
      createWarning(
        "VehicleLeaseAgreementAlreadyExistsException",
        false,
        this.translateService.instant("Exceptions.VehicleLeaseAgreementAlreadyExistsMessage")
      )
    ]
  }
  
  showAppropriateMessage(err: any) {
    var handledException = null;
    if (err && err.error && err.message) {
      handledException = this.handledExceptions.filter(x => err.message.includes(x.exceptionString))[0] as HandledException;
    }
    
    if (handledException == null) {
      handledException = this.handledExceptions.filter(x => err.error == x.exceptionString)[0] as HandledException;
    }

    if (handledException == null) {
      handledException = this.handledExceptions.filter(x => err.error?.errorType == x.exceptionString)[0] as HandledException;
    }

    if (handledException != null) {
      if (handledException.isSilent) {
        return;
      }

      this.show(handledException);
    } else {
      // Ohanterat fel
      this.showErrorAlert(err.displayMessage ||  this.translateService.instant("Exceptions.UnhandledExceptionMessage"));
    }
  }

  showError(message: string): void {
    alert("ShowError");
    this.showErrorAlert(message);
  }

  private show(handledException: HandledException) {
    if (handledException.type == ExceptionTypeEnum.Warn) {
      this.showCustomWarningAlert(handledException.message, 600, "center")
    } else if (handledException.type == ExceptionTypeEnum.Info) {
      this.showInformationAlert(handledException.message)
    } else if (handledException.type == ExceptionTypeEnum.Error) {
      this.showErrorAlert(handledException.message)
    } else {
      this.showErrorAlert(handledException.message)
    }
  }

    public showSuccessAlert(text: string): void {
        notify(
            {
                message: text,
                type: "success",
                displayTime: 3000,
                width: 400,
                position: "top",
                closeOnClick: true
            }
        );
    }

    public showErrorAlert(text: string): void {
        notify(
            {
                message: text,
                type: "error",
                displayTime: 10000,
                width: 400,
                position: "center",
                closeOnClick: true
            }
        );
    }

    public showWarningAlert(text: string): void {
        notify(
            {
                message: text,
                type: "warning",
                closeOnClick: true,
                position: "top",
                displayTime: 3000,
                width: 400,
            }
        );
    }

    public showCustomWarningAlert(text: string, width: number, position: string): void {
        notify(
            {
                contentTemplate: text,
                type: "warning",
                disableTimeOut: true,
                closeOnClick: true,
                position: position,
                width: width,
                tapToDismiss: true,
                displayTime: 90 * 1000,
                hideOnOutsideClick: true
            }
        );
    }

    public showInformationAlert(text: string): void {
        notify(
            {
                message: text,
                type: "info",
                displayTime: 3000,
                width: 400,
                position: "top",
                closeOnClick: true
            }
        );
    }
}