import {
  APP_INITIALIZER,
  ErrorHandler,
  enableProdMode,
  importProvidersFrom,
} from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import {
  withInterceptorsFromDi,
  provideHttpClient,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { ROUTES } from './app/routes';
import { AuthClientConfig } from '@auth0/auth0-angular';
import { UserService } from './app/services/user/user.service';
import { GlobalErrorHandler } from './app/services/global-error-handler';
import { locale, loadMessages } from 'devextreme/localization';
import config from 'devextreme/core/config';
import { licenseKey } from './devextreme-license';
import { registerLocaleData } from '@angular/common';
import localeSv from '@angular/common/locales/sv';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { lastValueFrom } from 'rxjs';
import { AuthHeaderInterceptor } from './app/services/http-interceptors/auth-header-interceptor';
import { ErrorInterceptor } from './app/services/http-interceptors/error-interceptor';
import dayjs from 'dayjs';
import * as sv from 'dayjs/locale/sv';
import { SettingsService } from './app/services/settings.service';

if (environment.production) {
  enableProdMode();
}

config({
  defaultCurrency: 'SEK',
  licenseKey,
});

let messagesSv = require('./assets/i18n/devextreme/sv.json');
loadMessages(messagesSv);
locale(navigator.language);
registerLocaleData(localeSv);

dayjs.locale(sv);

const version = '0.01';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    './assets/i18n/',
    '.json?version=' + version
  );
}

bootstrapApplication(AppComponent, {
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    importProvidersFrom(BrowserModule, RouterModule.forRoot(ROUTES)),

    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient, SettingsService],
        },
      })
    ),
    SettingsService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSettings,
      deps: [SettingsService, AuthClientConfig, UserService],
      multi: true,
    },
    //userServiceProvider,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch((err) => {
  console.error(err);
});

export function initializeSettings(
  settingsService: SettingsService,
  auth0Config: AuthClientConfig,
  userService: UserService
) {
  return async () => {
    await settingsService.load();
    auth0Config.set({
      ...SettingsService.settings.auth0,
      httpInterceptor: {
        allowedList: [
          `${SettingsService.settings.api.tenantServiceUri}/*`,
          `${SettingsService.settings.api.fleetSupplierServiceUri}/*`,
          `${SettingsService.settings.api.vehicleServiceUri}/*`,
          `${SettingsService.settings.api.invoicingServiceUri}/*`,
        ],
      },
    });
    try {
      await lastValueFrom(userService.load());
    } catch (error) {
      console.log(error);
    }
  };
}
