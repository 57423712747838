import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorService } from './error.service';
import { NotificationService } from './notification.service';


@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) { }

    handleError(error: any) {
        const errorService = this.injector.get(ErrorService);
        const notifier = this.injector.get(NotificationService);

        let displayMessage = error.displayMessage;
        let message;
        let stackTrace;

        if (error instanceof HttpErrorResponse) { // Nätverksfel
            if (error.status == 401 || error.status == 404)
                return;

            message = errorService.getServerMessage(error);
            stackTrace = errorService.getServerStack(error);

            // Sortera bort dessa fel
            if (message && message.includes('where a stream was expected'))
                return;

            if (message && message.toUpperCase().includes('CHUNKLOADERROR'))
                return;

            if (!displayMessage) {
                displayMessage = message;
            }
            if (notifier)
                notifier.showError(displayMessage);
        } else { // Klientfel
            message = errorService.getClientMessage(error);
            if (message.includes('where a stream was expected'))
                return;

            // Dessa fel uppstår när en deploy gjorts samtidigt som en användare är
            // inloggad och de olika "chucksen" inte har laddats in, fel "chunk"
            // kommer då att laddas. För att lösa detta problemet måste vi
            // på något sätt trigga en reload (signalr?) när en deploy gjorts.
            if (message.toUpperCase().includes('CHUNKLOADERROR'))
                return;

            stackTrace = errorService.getClientStack(error);

            if (!displayMessage) {
                displayMessage = message;
            }

            if (notifier) {
                // TODO: Svälj felet tillsvidare och visa inte för användaren.
                // notifier.showError(displayMessage);
            }
        }
        
        console.error(error);
    }
}