import { Component, OnInit } from '@angular/core';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { PageLoaderComponent } from './components/page-loader/page-loader';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import { locale } from 'devextreme/localization';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, PageLayoutComponent, PageLoaderComponent]
})

export class AppComponent implements OnInit {
  isLoadingUserData: boolean;
  title = 'portify-market';
  
  constructor(private translate: TranslateService) {
    this.setLanguage();
  }

  ngOnInit(): void {
   console.log("ONIT - APP")

  }

  setLanguage() {

    this.translate.setDefaultLang('en');
    const userLang = localStorage.getItem("user_language")

    if (userLang) {
      this.translate.use(userLang);
      dayjs.locale(userLang);
      locale(userLang);
    }
    else {
      let lang = this.translate.getBrowserLang();
      this.translate.use(lang);
      dayjs.locale(lang);
      locale(lang);
      localStorage.setItem("user_language", lang)
    }
  }
}
