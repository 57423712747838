export class SupplierAccount {
    id: string
    name: string;
    companyRegistrationNumber; string;
    ownerUserId: string;
    serviceCategories: Array<string>;
    serviceCategory: string;
    accountOwnerUserId: string;
    createdTime: Date;

    public hasServiceCategory(serviceCategory: string): boolean {
        return this.serviceCategories.some(s => s == serviceCategory);
    }
}