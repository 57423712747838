import { Injectable } from '@angular/core';
import { IAppSettings } from '../models/general/app-settings';

@Injectable()
export class SettingsService {
  static settings: IAppSettings;
  constructor() {}
  load() {
    const jsonFile = `assets/settings/settings.json`;

    return new Promise<void>((resolve, reject) => {
      fetch(jsonFile)
        .then((res) => res.json())
        .then((json) => {
          SettingsService.settings = json;
          console.log('Settings retrieved');
          resolve();
        })
        .catch((response: any) => {
          reject(
            `Could not load file '${jsonFile}': ${JSON.stringify(response)}`
          );
        });
    });
  }
}

const settingsServiceFactory = () => {
  var service = new SettingsService();
  service.load();
  return service;
};

export const settingsServiceProvider = {
  provide: SettingsService,
  useFactory: settingsServiceFactory,
  deps: [],
};
