import { EventEmitter, Injectable, Output, signal } from '@angular/core';
import * as auth0 from 'auth0-js';
import { environment } from 'src/environments/environment';
import { SettingsService } from '../settings.service';
import { initializeSettings } from 'src/main';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  @Output() onSuccessfulLogin = new EventEmitter();

  public isLoading = signal(false);

  authClient: auth0.WebAuth | null = null;

  constructor(private settings: SettingsService) {
    console.log('AuthService ' + SettingsService.settings);
    this.initAuthClient();
  }

  // Uggly workaround for dependency injcetion problem...
  initAuthClient = () => {
    if (SettingsService.settings && this.authClient == null) {
      this.authClient = new auth0.WebAuth({
        clientID: SettingsService.settings.auth0.clientId,
        domain: SettingsService.settings.auth0.domain,
        responseType: SettingsService.settings.auth0.responseType,
        audience: SettingsService.settings.auth0.authorizationParams.audience,
        redirectUri:
          SettingsService.settings.auth0.authorizationParams.redirect_uri,
        scope: SettingsService.settings.auth0.scope,
      });
    }
  };

  realm: string = 'Username-Password-Authentication';

  public login = (username: string, password: string): Promise<void> => {
    this.initAuthClient();
    return new Promise<void>((resolve, reject) => {
      this.authClient.login(
        {
          username: username,
          password: password,
          realm: this.realm,
        },
        (err) => {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        }
      );
    });
  };

  public logout = () => {
    this.initAuthClient();
    this.authClient.logout({
      returnTo: SettingsService.settings.auth0.authorizationParams.returnToUrl,
    });
  };

  public refreshToken = (): Promise<RefreshTokenResponse | undefined> => {
    this.initAuthClient();

    return new Promise<RefreshTokenResponse | undefined>((resolve, reject) => {
      this.authClient?.checkSession({}, (err, authResult) => {
        if (err) {
          return reject(err);
        }
        return resolve({
          access_token: authResult.accessToken,
          expires_in: authResult.expiresIn,
          id_token: authResult.idToken,
        });
      });
    });
  };

  public validateAuthResponse = async () => {
    this.initAuthClient();
    return await this.authClient.parseHash(
      { hash: window.location.hash },
      function (err, authResult) {
        if (err) {
          console.log(err);
          return false;
        }
        return authResult;
      }
    );
  };
}

export class RefreshTokenResponse {
  access_token!: string;
  id_token!: string;
  expires_in!: number;
}
