import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpHeaders,
  HttpContextToken,
} from '@angular/common/http';

import { Observable, from, lastValueFrom } from 'rxjs';
import { TokenService } from '../auth/token.service';

export const DISABLE_AUTH_HEADER_HANDLING = new HttpContextToken<boolean>(
  () => false
);

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  constructor(private readonly injector: Injector) {
    console.log('AuthHeaderInterceptor');
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.ignoreAuthHeaderHandling(req)) {
      return next.handle(req);
    }
    return from(this.handleSetToken(req, next));
  }

  private async handleSetToken(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    const tokenService = this.injector.get(TokenService);
    let idToken = await tokenService.getToken();
    req = req.clone({
      setHeaders: {
        Authorization: idToken,
      },
    });

    return await lastValueFrom(next.handle(req));
  }

  private ignoreAuthHeaderHandling(request: HttpRequest<any>) {
    return request.context.get(DISABLE_AUTH_HEADER_HANDLING);
  }
}
