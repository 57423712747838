export class HandledException {
    exceptionString: string;
    isSilent: boolean;
    type: ExceptionTypeEnum;
    message: string;

    public constructor(exceptionString: string, isSilent: boolean, type: ExceptionTypeEnum, message: string) {
        this.exceptionString = exceptionString;
        this.isSilent = isSilent;
        this.type = type;
        this.message = message;
    }
}

export function createWarning(exceptionString: string, isSilent: boolean, message: string): HandledException {
    return new HandledException(exceptionString, isSilent, ExceptionTypeEnum.Warn, message);
}

export function createInfo(exceptionString: string, isSilent: boolean, message: string): HandledException {
    return new HandledException(exceptionString, isSilent, ExceptionTypeEnum.Info, message);
}

export function createError(exceptionString: string, isSilent: boolean, message: string): HandledException {
    return new HandledException(exceptionString, isSilent, ExceptionTypeEnum.Error, message);
}

export enum ExceptionTypeEnum {
    Info,
    Warn,
    Error
}