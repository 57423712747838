import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpContextToken,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserService } from '../user/user.service';
import { DISABLE_AUTH_HEADER_HANDLING } from './auth-header-interceptor';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private readonly injector: Injector) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const userService = this.injector.get(UserService);
    if (this.ignoreErrorInterceptor(request)) {
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((err) => {
        if ([401, 403].includes(err.status) && userService.isLoggedIn) {
          userService.logout();
        }

        console.log(err);

        return throwError(() => err);
      })
    );
  }

  private ignoreErrorInterceptor(request: HttpRequest<any>) {
    return request.context.get(DISABLE_AUTH_HEADER_HANDLING);
  }
}
