import { Injectable, signal } from '@angular/core';
import {
  Observable,
  catchError,
  map,
  mergeMap,
  throwError,
} from 'rxjs';
import { TokenService } from '../auth/token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '../settings.service';
import { UserMapper } from 'src/app/models/user/mappers/user-mapper';
import { UserDto } from 'src/app/models/user/user-dto';
import { User } from 'src/app/models/user/user';
import { UserRequest } from 'src/app/models/user/requests/user-request';
import { SupplierAccount } from 'src/app/models/user/supplier-account';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  getUser = (): User => {
    const user: User = JSON.parse(localStorage.getItem('current_user'));
    return user;
  };

  public userSignal = signal<User | null>(null);
  public isAuthenticated = signal(false);
  public isLoading = signal(false);
  constructor(private tokenService: TokenService, private http: HttpClient) { }

  isLoggedIn = this.userSignal() !== null;
  logout = () => {
    this.tokenService.deleteToken();
    this.userSignal.set(null);
    this.isAuthenticated.set(false);
    localStorage.removeItem('selected-service-category');
  };

  load = (): Observable<any> => {
    this.isLoading.set(true);

    let chain: Observable<any> = this.http.get<User>(
      SettingsService.settings.api.tenantServiceUri + '/users/me'
    );

    return chain.pipe(
      catchError((err) => {
        this.isLoading.set(false);
        return throwError(() => err);
      }),
      mergeMap((u) => {
        return this.http
          .get<Array<SupplierAccount>>(
            `${SettingsService.settings.api.fleetSupplierServiceUri}/supplier-account/by-user-id/${u.id}`
          )
          .pipe(
            map((sa) => {
              if (sa && sa.length > 0) {
                u.supplierAccounts = sa;
                this.setSupplierAccount(sa[0], u);
              }
              this.isLoading.set(false);
              this.userSignal.set(u);
              this.isAuthenticated.set(true);
              return u;
            })
          );
      })
    );
  };

  getIdPSourceForUser(partnerName: string, userId: string): Observable<any> {
    const url = SettingsService.settings.api.tenantServiceUri + '/federatedAuthenticationProvider/user?partnerName=' + partnerName + '&userId=' + userId;
    return this.http.get<any>(url);
  }

  selectSupplierAccount(supplierAccountId: string) {
    var user = this.userSignal();
    var selectedSupplier = user.supplierAccounts.find(sa => sa.id == supplierAccountId);
    if (selectedSupplier != null) {
      this.setSupplierAccount(selectedSupplier, user);
      this.userSignal.set(user);
    }
  }

  setSupplierAccount(supplierAccount: SupplierAccount, user: User) {
    user.supplierAccount = Object.assign(new SupplierAccount(), supplierAccount);
    user.supplierAccountId = supplierAccount.id;
    user.supplierAccount.serviceCategories = supplierAccount.serviceCategories; //['vehicleFinancing', 'vehicleConversion', 'tireManagement']

    var selectedServiceCategory = localStorage.getItem(
      'selected-service-category'
    );
    if (
      selectedServiceCategory &&
      user.supplierAccount.serviceCategories.some(
        (s) => s == selectedServiceCategory
      )
    ) {
      user.supplierAccount.serviceCategory = selectedServiceCategory;
    } else {
      if (user.supplierAccount.serviceCategories.length == 1) {
        user.supplierAccount.serviceCategory = user.supplierAccount.serviceCategories[0];
        localStorage.setItem(
          'selected-service-category',
          user.supplierAccount.serviceCategory
        );
      }
    }
  }

  getUsers = (): Observable<Array<User>> => {
    const supplierAccountId = this.userSignal().supplierAccountId;

    const config: any = {
      url: `${SettingsService.settings.api.fleetSupplierServiceUri}/customers?accountId=${supplierAccountId}`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };

    return this.http.get<Array<User>>(config.url).pipe(
      map((response) => {
        return response.map((x) =>
          Object.assign(UserMapper.mapToDomain(x))
        );
      }),
    );
  }

  getUser2 = (userId: string): Observable<User> => {
    const config: any = {
      url: `${SettingsService.settings.api.fleetSupplierServiceUri}/customers`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    };

    return this.http.get<UserDto>(config.url).pipe(
      map((response: UserDto) => {
        return UserMapper.mapToDomain(response)
      })
    )
  };

  addUser = (request: UserRequest): Observable<any> => {
    const supplierAccountId = this.userSignal().supplierAccountId;
    const url = `${SettingsService.settings.api.fleetSupplierServiceUri}/customers?accountId=${supplierAccountId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    return this.http.post(url, JSON.stringify(request), httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  };

  updateUser = (user: UserDto): Observable<any> => {
    const supplierAccountId = this.userSignal().supplierAccountId;
    const url = `${SettingsService.settings.api.fleetSupplierServiceUri}/customers/${user.id}?accountId=${supplierAccountId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };
    return this.http.put(url, JSON.stringify(user), httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  };

  deleteUser = (userId: string): Observable<any> => {
    const supplierAccountId = this.userSignal().supplierAccountId;
    const url = `${SettingsService.settings.api.fleetSupplierServiceUri}/customers/${userId}?accountId=${supplierAccountId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    return this.http.delete(url, httpOptions).pipe(
      map((res) => {
        return res;
      })
    );
  };
}
