import { User } from '../user';
import { UserDto } from '../user-dto';

export class UserMapper {
  static mapToDto(customer: User): UserDto {
    var customerDto = new UserDto();
    customerDto.id = customer.id;

    return customerDto;
  }

  static mapToDomain(userDto: UserDto): User {
    var user = new User();
    user.id = userDto.id;
    // user.contact = userDto.contact;
    // user.customerNumber = userDto.customerNumber;
    // user.name = userDto.name;
    // user.companyRegistrationNumber = userDto.companyRegistrationNumber;
    // user.customerDisplayName = userDto.contact
    //   ? userDto.contact.firstName + ' ' + userDto.contact.lastName
    //   : '';
    // user.fleetTenantId = userDto.fleetTenantId;

    return user;
  }
}
